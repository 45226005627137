import { render, staticRenderFns } from "./MyTendersList.vue?vue&type=template&id=3209cc40&scoped=true&"
import script from "./MyTendersList.vue?vue&type=script&lang=js&"
export * from "./MyTendersList.vue?vue&type=script&lang=js&"
import style0 from "./MyTendersList.vue?vue&type=style&index=0&id=3209cc40&prod&lang=scss&scoped=true&"
import style1 from "./MyTendersList.vue?vue&type=style&index=1&id=3209cc40&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3209cc40",
  null
  
)

export default component.exports